import React from 'react';
import PropTypes from 'prop-types';
import style from './PlansFeatures.module.scss';
import classnames from 'classnames';

const PlansFeatures = ({ content, activepoint }) => {
  // console.log('items', content);
  // console.log('activepoint', activepoint);

  const filterData = content.filter((it) => it?.primary?.new_features);

  // console.log('fi', filterData);

  return (
    <div className={style.plan_feature_wrapper}>
      <div className={style.wrapper}>
        <div className={style.grid_container}>
          <div className={style.grid_item}>
            {content &&
              content.map((itm, idx) => {
                if (itm?.primary?.new_features) {
                  if (
                    itm?.primary?.new_features?.text ===
                    'business-package-features'
                  ) {
                    return (
                      <div key={idx}>
                        {itm?.items &&
                          itm?.items?.map((item, index) => (
                            <div
                              className={classnames(style.list_wrap, {
                                [style.even]: (index + 1) % 2 == 0,
                                [style.odd]: (index + 1) % 2 !== 0,
                              })}
                              key={index}
                            >
                              <p
                                className={classnames(style.list_contain, {
                                  [style.is_false]: item?.status === false,
                                })}
                              >
                                {item?.name?.text}
                              </p>
                            </div>
                          ))}
                      </div>
                    );
                  }
                }
              })}
          </div>

          <div className={style.grid_item}>
            {content &&
              content.map((itm, idx) => {
                if (itm?.primary?.new_features) {
                  if (
                    itm?.primary?.new_features?.text ===
                    'growth-package-features'
                  ) {
                    return (
                      <div key={idx}>
                        {itm?.items &&
                          itm?.items?.map((item, index) => (
                            <div
                              className={classnames(style.list_wrap, {
                                [style.even]: (index + 1) % 2 == 0,
                                [style.odd]: (index + 1) % 2 !== 0,
                              })}
                              key={index}
                            >
                              <p
                                className={classnames(style.list_contain, {
                                  [style.is_false]: item?.status === false,
                                })}
                              >
                                {item?.name?.text}
                              </p>
                            </div>
                          ))}
                      </div>
                    );
                  }
                }
              })}
          </div>
          <div className={style.grid_item}>
            {content &&
              content.map((itm, idx) => {
                if (itm?.primary?.new_features) {
                  if (
                    itm?.primary?.new_features?.text ===
                    'unlimited-package-features'
                  ) {
                    return (
                      <div key={idx}>
                        {itm?.items &&
                          itm?.items?.map((item, index) => (
                            <div
                              className={classnames(style.list_wrap, {
                                [style.even]: (index + 1) % 2 == 0,
                                [style.odd]: (index + 1) % 2 !== 0,
                              })}
                              key={index}
                            >
                              <p
                                className={classnames(style.list_contain, {
                                  [style.is_false]: item?.status === false,
                                })}
                              >
                                {item?.name?.text}
                              </p>
                            </div>
                          ))}
                      </div>
                    );
                  }
                }
              })}
          </div>
        </div>

        <div className={style.mobile_view_feature_plans}>
          {filterData &&
            filterData.map((item, idx) => (
              <>
                {idx === activepoint &&
                  item?.items.map((itm, index) => (
                    <div
                      className={classnames(style.list_wrap, {
                        [style.even]: (index + 1) % 2 == 0,
                        [style.odd]: (index + 1) % 2 !== 0,
                      })}
                      key={index}
                    >
                      <p
                        className={classnames(style.list_contain, {
                          [style.is_false]: itm?.status === false,
                        })}
                      >
                        {itm?.name?.text}
                      </p>
                    </div>
                  ))}
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

PlansFeatures.propTypes = {
  content: PropTypes.array.isRequired,
  activepoint: PropTypes.number,
};

export default PlansFeatures;
