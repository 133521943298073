import React from 'react';
import style from './TalkToExpert.module.scss';
import PropTypes from 'prop-types';

const TalkToExpert = ({ primary }) => {
  // console.log('hh', primary);

  return (
    <div className={style.container}>
      {primary && (
        <div className={style.talk_expert_wrap}>
          <div className={style.talk_expert_card}>
            <div className={style.text_wrap}>
              <div className={style.title}>
                {/* <RichText render={title.richText} /> */}

                <h4>{primary?.title?.text}</h4>
              </div>

              <div className={style.description}>
                {/* <RichText render={title.richText} /> */}
                <p>{primary?.subtitle?.text}</p>
              </div>
            </div>

            <div className={style.button_wrap}>
              <a href={primary?.buttonlink?.url} className={style.button}>
                <span>{primary?.buttontext?.text}</span>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TalkToExpert.propTypes = {
  primary: PropTypes.object,
};

export default TalkToExpert;
